import React from 'react';
import CustomNavbar from '../../components/CustomNavbar';
import Breadcrumb from '../../components/Breadcrumb';
import AboutDetails from './AboutDetails';
import FooterTwo from '../../components/Footer/FooterOther';
import FooterData from '../../components/Footer/FooterData';

const About = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="About Me" Pdescription="A brief introduction to myself as a yoga teacher."/>
            <AboutDetails />
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default About;