import React from 'react';
import TheInfiniteMatTitle from '../Title/TheInfiniteMatTitle';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';

const ServiceMiniblasta = () => {
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div className="container">
                    <TheInfiniteMatTitle Title='The infinite mat' TitleP='In the boundless space and countless time you are now.'/>
                    <div className="row seo_service_info">
                     
                        <div className="col-lg-12 text-center mt_40">
                            <a href=".#" className="seo_btn seo_btn_one btn_hover">Pratica online</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-6">
                            <div className="seo_features_img">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/miniblasta/service1.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <p> Grazie per essere qui a visitare il mio sito.<br/>
                                        Il mio nome è Fabrizia Binetti, sono una insegnante di yoga.<br/>
                                        Questo spazio è destinato alla condivisione di contenuti che nascono dalla mia passione e pratica dello yoga.
                                        Presto potremo praticare insieme e se nel frattempo hai domande o consigli sarò felice di ricevere un tuo messaggio. <br/>
                                    </p>
        
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/miniblasta/service2.png')} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content mb_300">
                                    <p className="wow fadeInUp">Quasi dimenticavo... il nome del mio assistente a quattro zampe è Marino. <br/>
                                    Praticare da soli è bello ma insieme è ...diverso 🙂 </p>
                                    <Link to="/Contact" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Contattaci</Link>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ServiceMiniblasta;
