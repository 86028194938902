import React, { Component } from "react";

class Instagramgalleryitem extends Component {
  render() {
    let { image, title, url } = this.props;
    return (
      <div className="col-lg-2 col-sm-4 portfolio_item br ux p0">
        <div className="instagram_img">
          <img src={image} alt="" />
          <div href={url} className="hover_content">
            <a href={url} className="portfolio-title">
              <div className="portfolio-description leaf">
                  <h3 className="f_300 f_size_15 f_p">{title}</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Instagramgalleryitem;
