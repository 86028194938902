import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./assets/themify-icon/themify-icons.css";
import "./assets/simple-line-icon/simple-line-icons.css";
import "./assets/font-awesome/css/all.css";
import "./assets/elagent/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.css";
import "./assets/responsive.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCxco96qKb6rEsVhaRjB92_hX-3XAY146M",
  authDomain: "theinfinitemat.firebaseapp.com",
  projectId: "theinfinitemat",
  storageBucket: "theinfinitemat.appspot.com",
  messagingSenderId: "810939200974",
  appId: "1:810939200974:web:08ddcf98493f97b954679f",
  measurementId: "G-M7W3L4B86S",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const loader = document.querySelector("#preloader");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("loader--hide");

setTimeout(
  () =>
    // the show/hide functions are passed as props
    ReactDOM.render(<App hideLoader={hideLoader} showLoader={showLoader} />, document.getElementById("root")),
  1000
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
