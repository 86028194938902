import React, { Component } from "react";
import Eventgalleryitem from "./Eventgalleryitem";
import Instagramgalleryitem from "./Instagramgalleryitem";
import axios from "axios";

const size = 24;

class Eventgallery extends Component {
  constructor() {
    super();
    let url =
      `https://graph.instagram.com/17841400318172758/media?access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}&fields=media_url,thumbnail_url,caption,permalink,id,media_type&media_type=images`;
    this.state = {
      images: [],
    };
    axios
      .get(url) 
      .then((res) => {
        console.log(res.data.paging.next);

        axios
          .get(res.data.paging.next)
          .then((res2) => {
            let images = [...res.data.data, ...res2.data.data]
            .filter((item) => (item.media_type == "IMAGE" || item.media_type == "CAROUSEL_ALBUM") && item.caption.includes("#yogainspiration"))
            .slice(0, size);
            this.setState({ images: images });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <section className="event_gallery_area">
          <p className="row m01">Seguimi su&nbsp;<b>Instagram</b>&nbsp;@miniblasta</p>
        <div className="row m0l portfolio_gallery event_gallery">
          {this.state.images.map((post) => {
            let topIndex = post.caption.indexOf("#");
            console.log("topIndex", topIndex);

            if (topIndex > 0) {
              topIndex = post.caption.indexOf(". . .") > 0 ? post.caption.indexOf("...") : topIndex;
            } else {
              topIndex = post.caption.length;
            }
            let caption = post.caption.substr(0, topIndex);
            if (caption.length > 100) {
              caption = caption.substr(0, 97) + "...";
            }
            return <Instagramgalleryitem image={post.media_url} key={post.id} title={caption} url={post.permalink} />;
          })}
        </div>
      </section>
    );
  }
}
export default Eventgallery;
