import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

const toastOptions = {
  position: toast.POSITION.BOTTOM_CENTER,
};

class Contacts extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      recaptchaValue: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, subject, message } = this.state;
    let templateParams = {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    };

    emailjs
      .send('service_pijt1hd', 'template_reuiw2v', templateParams, 'user_ZiN5pZ1bn2fSkXp7MZ7ts')
      .then((res) => {
        toast("🦄 Your message succesfully sent!", toastOptions);
        this.resetForm();
      })
      .catch((err) => {
        toast.error("Opps! Something went wrong, but it’s not your fault. Please try again later", toastOptions);
      });
  };

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  }

  onChange() {
    let stateObj = Object.assign({},this.state);  // creating copy of state variable jasper
    stateObj.recaptchaValue = recaptchaRef.current.getValue();                     
    this.setState(stateObj);
  }

  validateForm(){
      return this.state.name && this.state.email && this.state.subject && this.state.message && this.state.recaptchaValue
  }

  render() {
    return (
      <section className="contact_info_area sec_pad bg_color">
        <div className="container">
          <div className="row">
            <div className="contact_info_item">
              <h6 className="f_p f_size_20 t_color3 f_500 mb_20">Contact Info</h6>

              <p className="f_400 f_size_15">
                <span className="f_400 t_color3">Email:</span> <a href="mailto:theinfinitemat@gmail.com">theinfinitemat@gmail.com</a>
              </p>
            </div>
            <div className="contact_form col-lg-9">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">Leave a Message</h2>
              <form onSubmit={this.handleSubmit} className="contact_form_box" method="post" id="contactForm">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input type="text" id="name" name="name" placeholder="Your Name" value={this.state.name} onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input type="text" name="email" id="email" placeholder="Your Email" value={this.state.email} onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input type="text" id="subject" name="subject" placeholder="Subject" value={this.state.subject} onChange={this.handleChange} />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea onChange={this.handleChange} name="message" id="message" cols="30" rows="10" value={this.state.message} placeholder="Enter Your Message . . ."></textarea>
                    </div>
                  </div>
                </div>
                <ReCAPTCHA ref={recaptchaRef} sitekey='6LfrgsEaAAAAANpQ4LyG9wSggdD41Xa0KvIwxvfp' onChange={this.onChange} />
                <button type="submit" className={this.validateForm()?"btn_three":"btn_three_disabled"} disabled={!this.validateForm()}>
                  Send Message
                </button>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
