import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import DesignBanner from '../components/Banner/DesignBanner';
import ServiceMiniblasta from '../components/Service/ServiceMiniblasta';
import Subscribe from '../components/Subscribe';
import Eventgallery from '../components/Portfolios/Eventgallery';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

export const Home = () => (
    <div className="body_wrapper">
        <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
        <DesignBanner/>
        <ServiceMiniblasta/>
        <Eventgallery/>
        <Footer FooterData={FooterData}/>
    </div>
)