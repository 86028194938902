import React from "react";
import Map from "./Map";
const EventLocation = () => {
  return (
    <section className="event_location_area">
      <Map />
      <div className="container">
        <p>
          <a href="mailto:theinfinitemat@gmail.com">theinfinitemat@gmail.com</a>
        </p>
      </div>
    </section>
  );
};
export default EventLocation;
