import React from "react";
import Reveal from "react-reveal/Reveal/";

const DesignBanner = () => {
  return (
    <section className="seo_home_area">
        <div className="saas_home_img">
          <Reveal effect="fadeInUp" duration={1400}>
            <img src={require("../../img/miniblasta/header.PNG")} alt="" />
          </Reveal>
      </div>
    </section>
  );
};
export default DesignBanner;
