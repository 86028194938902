import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";
class AboutDetails extends Component {
  render() {
    return (
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2 className="f_size_30 f_600 t_color3 l_height40 text-center  wow fadeInUp" data-wow-delay="0.2s">
            Fabrizia Binetti
          </h2>
          <h4 className="f_size_20 f_400 t_color4 l_height40 text-center mb_90 wow fadeInUp" data-wow-delay="0.5s">
            Yoga Teacher
          </h4>

          <div className="row mb_30">
            <div className="col-lg-4 col-sm-4"> </div>
            <div className="col-lg-4 col-sm-4 text-center ">
              <Reveal effect="fadeInUp" duration={1100}>
                <div className="about_img_miniblasta">
                  <img src={require("../../img/miniblasta/service1.png")} alt="" />
                </div>
                <h5 />
                Il suo percorso con lo yoga inizia a Roma nel 2015 dove si avvicina agli stili Hatha ed Anukalana. Dal 2017, parallelamente al corso di formazione inizia a praticare stili
                più dinamici come Vinyasa ed Ashtanga. <br /> Attualmente nelle sue pratiche integra elementi classici dell’hatha con il dinamismo e la creatività del Vinyasa. Il
                bilanciamento di forza e mobilità è un elemento chiave nel suo insegnamento. La pratica delle asana è spesso accompagnata da pranayama ed altre tecniche atte ad accrescere la
                propriocezione per migliorare l’approccio a qualsiasi tipo di attività.
              </Reveal>
            </div>
          </div>
        </div>

        <div className="container mt_300">
          <div className="partner_logo_area_four">
            <h4 className="f_size_18 f_400 f_p text-center l_height28 mb_70"></h4>
            <div className="row partner_info_cert">
              <div className="logo_item_cert wow fadeInLeft" data-wow-delay="0.1s">
                {/* <a href=".#"> */}
                  <img src={require("../../img/miniblasta/certificazioni/yoga_alliance.png")} alt="" />
                {/* </a> */}
              </div>
              <div className="logo_item_cert wow fadeInLeft" data-wow-delay="0.2s">
                {/* <a href=".#"> */}
                <img src={require("../../img/miniblasta/certificazioni/coni.png")} alt="" />
                {/* </a> */}
              </div>
              <div className="logo_item_cert wow fadeInLeft" data-wow-delay="0.3s">
                {/* <a href=".#"> */}
                <img src={require("../../img/miniblasta/certificazioni/csen.png")} alt="" />
                {/* </a> */}
              </div>
              {/*<div className="logo_item wow fadeInLeft" data-wow-delay="0.4s">
                <a href=".#">
                  <img src={require("../img/home3/logo_04.png")} alt="" />
                </a>
              </div>
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.5s">
                <a href=".#">
                  <img src={require("../img/home3/logo_05.png")} alt="" />
                </a>
              </div>
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.6s">
                <a href=".#">
                  <img src={require("../img/home3/logo_01.png")} alt="" />
                </a>
              </div>
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.7s">
                <a href=".#">
                  <img src={require("../img/home3/logo_04.png")} alt="" />
                </a>
              </div>
              <div className="logo_item wow fadeInLeft" data-wow-delay="0.8s">
                <a href=".#">
                  <img src={require("../img/home3/logo_05.png")} alt="" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default AboutDetails;
